<template>
    <div class="we-are-sorry">
        <Navbar :topbarColor="topbarColor" :logo="require('@/assets/images/logo/logo-light-icon.png')" :title="logotitle" />
        <div class="d-flex justify-content-center align-items-center container-was">
            <div class="card mx-3">
                <div class="card-body mx-5 my-3">
                    <div class="d-md-flex py-3">
                        <h1 class="font-weight-normal mr-2 mb-3 mb-md-0">Terima kasih sudah bergabung dengan </h1>
                        <img src="@/assets/images/logo/soca-black.svg" alt="" class="img-mobile">
                    </div>
                    <h4 class="font-weight-normal text-center mt-md-3 mb-3">Tunggu kejutan dari kami <br> tanggal 10 Oktober 2022 nanti, Sabar ya!</h4>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Navbar from '@/components/navigation/Navbar'
export default {
    name: "Report",
    components: {
        Navbar
    },
    data() {
        return {
        };
    },
    computed: {
    },
    watch: {
    },
    mounted() {
    },
    methods: {
    },
    middleware: 'router-auth'
};
</script>
<style>
* {
	-webkit-box-sizing: border-box;
	box-sizing: border-box
}

body {
	padding-top: 0;
	margin: 0
}

.container-was{
    padding-top: 30vh;
}

#notfound {
	position: relative;
	height: 100vh
}

#notfound .notfound {
	position: absolute;
	left: 50%;
	top: 50%;
	-webkit-transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%)
}

.we-are-sorry .notfound {
	max-width: 920px;
	width: 100%;
	line-height: 1.4;
	text-align: center;
	padding-left: 15px;
	padding-right: 15px
}

.we-are-sorry h1{
    font-size: 40px;
}

.we-are-sorry h4{
    font-size: 30px;
}

.we-are-sorry .card{
    border-radius: 20px;
}


.notfound .notfound-404 {
	position: absolute;
	height: 100px;
	top: 0;
	left: 50%;
	-webkit-transform: translateX(-50%);
	-ms-transform: translateX(-50%);
	transform: translateX(-50%);
	z-index: -1
}

.notfound .notfound-404 h1 {
	font-family: maven pro, sans-serif;
	color: #ececec;
	font-weight: 900;
	font-size: 276px;
	margin: 0;
	position: absolute;
	left: 50%;
	top: 50%;
	-webkit-transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%)
}

.notfound h2 {
	font-family: maven pro, sans-serif;
	font-size: 46px;
	color: #000;
	font-weight: 900;
	text-transform: uppercase;
	margin: 0
}

.notfound p {
	font-family: maven pro, sans-serif;
	font-size: 16px;
	color: #000;
	font-weight: 400;
	text-transform: uppercase;
	margin-top: 15px
}

.notfound a {
	font-family: maven pro, sans-serif;
	font-size: 14px;
	text-decoration: none;
	text-transform: uppercase;
	background: #189cf0;
	display: inline-block;
	padding: 16px 38px;
	border: 2px solid transparent;
	border-radius: 40px;
	color: #fff;
	font-weight: 400;
	-webkit-transition: .2s all;
	transition: .2s all
}

.notfound a:hover {
	background-color: #fff;
	border-color: #189cf0;
	color: #189cf0
}

@media only screen and (max-width:480px) {
	.notfound .notfound-404 h1 {
		font-size: 162px
	}
	.notfound h2 {
		font-size: 26px
	}

    
    .we-are-sorry h1{
        font-size: 20px;
    }

    .we-are-sorry h4{
        font-size: 14px;
    }

    .we-are-sorry .img-mobile{
        width : 90px;
        height : 20px;
    }

    .we-are-sorry .vs-navbar--header .hiddenDesktop {
        display: none;
    }

}
</style>